/* eslint-env browser */
/* global PARTYKIT_HOST */

import "./styles.css";
import PartySocket from "partysocket";

// DOM element to append all messages we get
const output = document.getElementById("app");

// Helper function to add a new line to the DOM
function add(text) {
  const newLine = document.createElement("div");
  newLine.textContent = text;
  output.appendChild(newLine);
}

// Initialize PartySocket connection
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

// Declare pingInterval for later use
let pingInterval;

// Event listener for when the connection opens
conn.addEventListener("open", function () {


  // Initialize pingInterval
  clearInterval(pingInterval);
  pingInterval = setInterval(function () {
    conn.send("ping");
  }, 2000); // Adjusted to send a ping every 2 seconds
});

// Event listener to handle received messages
conn.addEventListener("message", function (event) {

});

// Particle animation setup
document.addEventListener("DOMContentLoaded", function () {
  particlesJS("particles-js", {
    particles: {
      number: {
        value: 80,
        density: {
          enable: true,
          value_area: 800
        }
      },
      color: {
        value: "#000000"
      },
      shape: {
        type: "circle",
        stroke: {
          width: 0,
          color: "#000000"
        },
        polygon: {
          nb_sides: 5
        }
      },
      opacity: {
        value: 0.5,
        random: false,
        anim: {
          enable: false,
          speed: 1,
          opacity_min: 0.1,
          sync: false
        }
      },
      size: {
        value: 3,
        random: true,
        anim: {
          enable: false,
          speed: 40,
          size_min: 0.1,
          sync: false
        }
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: "#000000",
        opacity: 0.4,
        width: 1
      },
      move: {
        enable: true,
        speed: 6,
        direction: "none",
        random: false,
        straight: false,
        out_mode: "out",
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200
        }
      }
    },
    interactivity: {
      detect_on: "canvas",
      events: {
        onhover: {
          enable: true,
          mode: "repulse"
        },
        onclick: {
          enable: true,
          mode: "push"
        },
        resize: true
      },
      modes: {
        grab: {
          distance: 400,
          line_linked: {
            opacity: 1
          }
        },
        bubble: {
          distance: 400,
          size: 40,
          duration: 2,
          opacity: 8,
          speed: 3
        },
        repulse: {
          distance: 200,
          duration: 0.4
        },
        push: {
          particles_nb: 4
        },
        remove: {
          particles_nb: 2
        }
      }
    },
    retina_detect: true
  });
});
